import { createActions, } from 'redux-actions';

export default createActions({
  SEAT: {
    REQUEST: null,
    SET: null,
    TAKE: null,
    LEAVE: null,
  },
  TEMPORARY_UNCLICKABLE: {
    SET: null,
    RESET: null,
  },
});
