import card2c from '../../../assets/cards/2c.svg';
import card2d from '../../../assets/cards/2d.svg';
import card2h from '../../../assets/cards/2h.svg';
import card2s from '../../../assets/cards/2s.svg';
import card3c from '../../../assets/cards/3c.svg';
import card3d from '../../../assets/cards/3d.svg';
import card3h from '../../../assets/cards/3h.svg';
import card3s from '../../../assets/cards/3s.svg';
import card4c from '../../../assets/cards/4c.svg';
import card4d from '../../../assets/cards/4d.svg';
import card4h from '../../../assets/cards/4h.svg';
import card4s from '../../../assets/cards/4s.svg';
import card5c from '../../../assets/cards/5c.svg';
import card5d from '../../../assets/cards/5d.svg';
import card5h from '../../../assets/cards/5h.svg';
import card5s from '../../../assets/cards/5s.svg';
import card6c from '../../../assets/cards/6c.svg';
import card6d from '../../../assets/cards/6d.svg';
import card6h from '../../../assets/cards/6h.svg';
import card6s from '../../../assets/cards/6s.svg';
import card7c from '../../../assets/cards/7c.svg';
import card7d from '../../../assets/cards/7d.svg';
import card7h from '../../../assets/cards/7h.svg';
import card7s from '../../../assets/cards/7s.svg';
import card8c from '../../../assets/cards/8c.svg';
import card8d from '../../../assets/cards/8d.svg';
import card8h from '../../../assets/cards/8h.svg';
import card8s from '../../../assets/cards/8s.svg';
import card9c from '../../../assets/cards/9c.svg';
import card9d from '../../../assets/cards/9d.svg';
import card9h from '../../../assets/cards/9h.svg';
import card9s from '../../../assets/cards/9s.svg';
import cardTc from '../../../assets/cards/Tc.svg';
import cardTd from '../../../assets/cards/Td.svg';
import cardTh from '../../../assets/cards/Th.svg';
import cardTs from '../../../assets/cards/Ts.svg';
import cardJc from '../../../assets/cards/Jc.svg';
import cardJd from '../../../assets/cards/Jd.svg';
import cardJh from '../../../assets/cards/Jh.svg';
import cardJs from '../../../assets/cards/Js.svg';
import cardQc from '../../../assets/cards/Qc.svg';
import cardQd from '../../../assets/cards/Qd.svg';
import cardQh from '../../../assets/cards/Qh.svg';
import cardQs from '../../../assets/cards/Qs.svg';
import cardKc from '../../../assets/cards/Kc.svg';
import cardKd from '../../../assets/cards/Kd.svg';
import cardKh from '../../../assets/cards/Kh.svg';
import cardKs from '../../../assets/cards/Ks.svg';
import cardAc from '../../../assets/cards/Ac.svg';
import cardAd from '../../../assets/cards/Ad.svg';
import cardAh from '../../../assets/cards/Ah.svg';
import cardAs from '../../../assets/cards/As.svg';
import backRed from '../../../assets/cards/ww.svg';

export const BACK_CARD_NAME = 'ww';

const dictionaryObj = {
  '2c': card2c,
  '2d': card2d,
  '2h': card2h,
  '2s': card2s,
  '3c': card3c,
  '3h': card3h,
  '3d': card3d,
  '3s': card3s,
  '4c': card4c,
  '4d': card4d,
  '4h': card4h,
  '4s': card4s,
  '5c': card5c,
  '5d': card5d,
  '5h': card5h,
  '5s': card5s,
  '6c': card6c,
  '6d': card6d,
  '6h': card6h,
  '6s': card6s,
  '7c': card7c,
  '7d': card7d,
  '7h': card7h,
  '7s': card7s,
  '8c': card8c,
  '8d': card8d,
  '8h': card8h,
  '8s': card8s,
  '9c': card9c,
  '9d': card9d,
  '9h': card9h,
  '9s': card9s,
  Tc: cardTc,
  Td: cardTd,
  Th: cardTh,
  Ts: cardTs,
  Jc: cardJc,
  Jd: cardJd,
  Jh: cardJh,
  Js: cardJs,
  Qc: cardQc,
  Qd: cardQd,
  Qh: cardQh,
  Qs: cardQs,
  Kc: cardKc,
  Kd: cardKd,
  Kh: cardKh,
  Ks: cardKs,
  Ac: cardAc,
  Ad: cardAd,
  Ah: cardAh,
  As: cardAs,
  ww: backRed,
};

const getCard = (cardName) => dictionaryObj[cardName];

export default getCard;
