import { createSelector, } from 'reselect';
import { propOr, pathOr, path, } from 'ramda';
import { authSelector, definitionsSelector, } from './config';
import { userSelector, } from './game';

export const videoConfigSelector = createSelector(
  authSelector,
  definitionsSelector,
  userSelector,
  (auth, definitions, user) => ({
    operatorId: parseInt(auth.operatorId, 10),
    gameId: parseInt(auth.game_id, 10),
    tableId: parseInt(auth.table_id, 10),
    identifier: auth.identifier,
    cdnLocation: definitions.VIDEO_CDN_SERVICE,
    playerId: parseInt(user.sessionId, 10),
  })
);

export const videoSelector = propOr({}, 'video');

export const videoDelaySelector = createSelector(videoSelector, pathOr('0', [ 'cdnList', 'videoDelay', ]));

export const videoReadyStatusSelector = createSelector(videoSelector, propOr(false, 'ready'));

export const videoApiUrlSelector = createSelector(videoSelector, path([ 'cdnList', 'location', ]));

export const videoStreamIdSelector = createSelector(videoSelector, path([ 'cdnList', 'url', ]));

export const videoReconnectSelector = createSelector(videoSelector, propOr(0, 'reconnect'));
