import { concat, of, } from 'rxjs';
import playersActions from '../../actions/players';
import { CARD_TYPES_MAP, } from '../../constants/seats';

const { seats, } = playersActions;

const getCardsUpdateStore = ({ CardRelevance, }) => CARD_TYPES_MAP[CardRelevance] || CARD_TYPES_MAP.Relevant;

export default function handleCardMessage(socketMessage) {
  const {
    CardName,
    CardValue,
    TotalCardValues,
    TotalResult,
    SeatId,
    CardRelevance,
    ...rest
  } = socketMessage;
  const cardsUpdateStore = getCardsUpdateStore(socketMessage);

  return concat(
    of(
      seats.add({
        [cardsUpdateStore]: {
          cardName: CardName,
          cardValue: CardValue,
          totalCardValues: TotalCardValues,
          totalResult: TotalResult,
          seatId: SeatId,
          ...rest,
        },
      }),
      seats.normalizeScores({
        playerSeat: {
          seatId: SeatId,
        },
      })
    )
  );
}
