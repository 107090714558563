import {
  ascend, descend, prop, sort,
} from 'ramda';
import standSVG from '../../assets/decisions/stand.svg';
import hitSVG from '../../assets/decisions/hit.svg';
import doubleSVG from '../../assets/decisions/double.svg';
import splitSVG from '../../assets/decisions/split.svg';
import insuranceYesSVG from '../../assets/decisions/insuranceYes.svg';
import insuranceSurrenderSVG from '../../assets/decisions/insuranceSurrender.svg';
import insuranceNoSVG from '../../assets/decisions/insuranceNo.svg';

export const isSplitSeat = ({ seatId, }) => seatId.indexOf('-2') !== -1;
export const sortDescendBySeatId = sort(descend(prop('seatId')));
export const sortDescendByIndex = sort(descend(prop('index')));
export const sortAscendByValue = sort(ascend(prop('value')));

export const AVAILABLE_DECISIONS_ICONS = {
  /* CARDS DECISIONS */
  BettingCallsStand: {
    svg: standSVG,
    fillClassName: 'ststand0',
    actionButton: 'stand',
  },
  Stand: {
    svg: standSVG,
    fillClassName: 'ststand0',
    actionButton: 'stand',
  }, // sent by API on reconnect
  BettingCallsHit: {
    svg: hitSVG,
    fillClassName: 'sthit0',
    actionButton: 'hit',
  },
  Hit: {
    svg: hitSVG,
    fillClassName: 'sthit0',
    actionButton: 'hit',
  }, // sent by API on reconnect
  BettingCallsDouble: {
    svg: doubleSVG,
    fillClassName: 'stdouble0',
    actionButton: 'doubleBet',
  },
  Double: {
    svg: doubleSVG,
    fillClassName: 'stdouble0',
    actionButton: 'doubleBet',
  }, // sent by API on reconnect
  BettingCallsSplit: {
    svg: splitSVG,
    fillClassName: 'stSplit0',
    actionButton: 'split',
  },
  Split: {
    svg: splitSVG,
    fillClassName: 'stSplit0',
    actionButton: 'split',
  },
  /* INSURANCE DECISIONS */
  Insurance: {
    svg: insuranceYesSVG,
    fillClassName: 'stInsuranceYes0',
    actionButton: 'yesInsurance',
  },
  Surrender: {
    svg: insuranceSurrenderSVG,
    fillClassName: 'stInsuranceSurrender0',
    actionButton: 'surrenderInsurance',

  },
  Cancel: {
    svg: insuranceNoSVG,
    fillClassName: 'stInsuranceNo0',
    actionButton: 'noInsurance',

  },
};
