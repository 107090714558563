import { of, } from 'rxjs';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';
import { gameGameResultsSelector, } from '../../selectors';
import { SERVER_PERFECT_PAIR, SERVER_TWENTY_ONE, } from '../../../constants/betTypes';

const {
  gameActions: { game, },
} = bootstrapActions;

export const extractWonSidebets = (wonSidebets) => Object.entries(wonSidebets).reduce(
  (acc, [ betType, value, ]) => ([ SERVER_PERFECT_PAIR, SERVER_TWENTY_ONE, ].includes(betType)
    ? [ ...acc, { ...value, betType, }, ] : acc),
  []
);

export default function handleWonBets({ WonSideBets, }, state) {
  const gameResults = gameGameResultsSelector(state);
  const wonSidebets = extractWonSidebets(WonSideBets);

  return of(...[
    game.set({
      gameResults: {
        ...gameResults,
        wonSidebets,
      },
    }),
  ]);
}
