import { of, empty, } from 'rxjs';
import { ofType, combineEpics, } from 'redux-observable';
import {
  map,
  flatMap,
  pluck,
  filter,
  takeUntil,
  switchMap,
  skipUntil,
  take,
  distinctUntilChanged,
} from 'rxjs/operators';
import { keys, equals, } from 'ramda';
import { actions, } from '@ezugi/bootstrap';
import { NO_MORE_BETS, PLACE_YOUR_BETS, } from '@ezugi/constants';
import analyticsActions from '../../actions/analytics';
import insuranceActions from '../../actions/insurance';
import decisionActions from '../../actions/decision';

import {
  tableIdSelector,
  roundIdSelector,
  freeSeatsSelector,
  playerSeatsSelector,
  currentBetsSelector,
  clientIdSelector,
  playerSeatsIdsSelector,
} from '../../selectors';

import { SEAT_TAKEN, CALL_BET_DECISION, CALL_BETS, } from '../seats/constants';

const { analytics, } = analyticsActions;
const { insurance, } = insuranceActions;
const { decision, } = decisionActions;

const {
  socketActions: { socket, },
} = actions;

const insuranceEpic = (action$, state$) => action$.pipe(
  ofType(insurance.request),
  pluck('payload', 'type'),
  map((type) => {
    const state = state$.value;
    const { seatId, timestamp: insuranceStart, } = state.round.insurance;
    const roundId = roundIdSelector(state);
    const tableId = tableIdSelector(state);

    return analytics.push({
      event: 'bj_insurance',
      decision: type,
      roundId,
      tableId,
      seatId,
      duration: insuranceStart
        ? Math.ceil((Date.now() - insuranceStart) / 1000)
        : null,
    });
  })
);

const autoStandEpic = (action$, state$) => action$.pipe(
  ofType(socket.message),
  pluck('payload'),
  filter(
    ({ MessageType, SeatId, }) => MessageType === CALL_BETS
        && playerSeatsIdsSelector(state$.value).includes(SeatId)
  ),
  switchMap(() => action$.pipe(
    takeUntil(action$.ofType(decision.send)),
    ofType(socket.message),
    pluck('payload'),
    filter(
      ({ MessageType, SeatId, }) => MessageType === CALL_BET_DECISION
            && playerSeatsIdsSelector(state$.value).includes(SeatId)
    ),
    map(({ SeatId, }) => {
      const state = state$.value;
      const roundId = roundIdSelector(state);
      const tableId = tableIdSelector(state);

      return analytics.push({
        event: 'bj_decision',
        decision: 'Auto Stand',
        roundId,
        tableId,
        seatId: SeatId,
      });
    })
  ))
);

const decisionRequestEpic = (action$, state$) => action$.pipe(
  ofType(decision.request),
  pluck('payload'),
  filter((payload) => !payload.isEarlyDecision),
  map(({ type, seatId, }) => {
    const state = state$.value;
    const roundId = roundIdSelector(state);
    const tableId = tableIdSelector(state);

    return analytics.push({
      event: 'bj_decision',
      decision: type,
      roundId,
      tableId,
      seatId,
    });
  })
);

const earlyDecisionEpic = (action$, state$) => action$.pipe(
  ofType(decision.early),
  pluck('payload'),
  filter(({ earlyDecision, }) => earlyDecision != null),
  map(({ earlyDecision, seatId, }) => {
    const state = state$.value;
    const roundId = roundIdSelector(state);
    const tableId = tableIdSelector(state);

    return analytics.push({
      event: 'bj_predecision',
      decision: earlyDecision,
      roundId,
      tableId,
      seatId,
    });
  })
);

const seatTakenEpic = (action$, state$) => action$.pipe(
  ofType(socket.message),
  pluck('payload'),
  // Filter events for current player
  filter(
    ({ MessageType, playerOnSeatId, }) => MessageType === SEAT_TAKEN
        && clientIdSelector(state$.value) === playerOnSeatId
  ),
  map((socketMessage) => {
    const { SeatId, } = socketMessage;

    const roundId = roundIdSelector(state$.value);
    // Account for current seat
    const openSeats = freeSeatsSelector(state$.value).filter(
      ({ seatId, }) => seatId !== SeatId
    ).length;

    return analytics.push({
      event: 'bj_take_seat',
      seatId: SeatId,
      roundId,
      openSeats,
    });
  })
);

const seatsOccupiedEpic = (action$, state$) => action$.pipe(
  ofType(socket.message),
  pluck('payload', 'MessageType'),
  filter(equals(NO_MORE_BETS)),
  skipUntil(
    action$.pipe(
      ofType(socket.message),
      pluck('payload', 'MessageType'),
      distinctUntilChanged(),
      filter(equals(PLACE_YOUR_BETS)),
      take(1)
    )
  ),
  flatMap(() => {
    const numberOfSeats = playerSeatsSelector(state$.value).seats.length;

    return numberOfSeats > 0
      ? of(
        analytics.push({
          event: 'bj_nr_seats',
          numberOfSeats,
        })
      )
      : empty();
  })
);

const occupiedSeatsNoBetEpic = (action$, state$) => action$.pipe(
  ofType(socket.message),
  pluck('payload', 'MessageType'),
  filter(equals(NO_MORE_BETS)),
  skipUntil(
    action$.pipe(
      ofType(socket.message),
      pluck('payload', 'MessageType'),
      distinctUntilChanged(),
      filter(equals(PLACE_YOUR_BETS)),
      take(1)
    )
  ),
  flatMap(() => {
    const { seats, } = playerSeatsSelector(state$.value);
    const seatsWithBets = keys(currentBetsSelector(state$.value));

    const seatsWithoutBets = seats.filter(
      ({ seatId, }) => !seatsWithBets.includes(seatId)
    );
    const numberOfSeats = seatsWithoutBets.length;

    return numberOfSeats > 0
      ? of(
        analytics.push({
          event: 'bj_seats_no_bet',
          numberOfSeats,
        })
      )
      : empty();
  })
);

export default combineEpics(
  earlyDecisionEpic,
  autoStandEpic,
  decisionRequestEpic,
  insuranceEpic,
  seatTakenEpic,
  seatsOccupiedEpic,
  occupiedSeatsNoBetEpic
);
