/* eslint-disable consistent-return */

import { MEDIA, } from '../constants';

const getInvalidPutParamsError = (key) => `Invalid parameters to put, (${key}, undefined)`;

const getNotFoundError = (k) => `Not Found [${k}]`;

const _notFound = (k) => {
  const err = new Error(getNotFoundError(k));
  err.notFound = true;
  err.key = k;
  return err;
};

export class LocalStorage {

  constructor(namespace) {
    this._namespace = namespace;
    this._store = window.localStorage;
    this._sep = '\x00';
  }

  static clear() {
    window.localStorage.clear();
  }

  get(key) {
    const k = [ this._namespace, key, ].join(this._sep);
    if (!this._store[k]) return [ _notFound(k), ];

    try {
      return [ null, JSON.parse(this._store[k]), ];
    } catch (err) {
      return [ err, ];
    }
  }

  put(key, value) {
    if (typeof value === 'undefined') {
      return [ new Error(getInvalidPutParamsError(key)), ];
    }

    try {
      const k = [ this._namespace, key, ].join(this._sep);
      const v = JSON.stringify(value);
      const result = (this._store[k] = v);
      return [ null, result, ];
    } catch (err) {
      return [ err, ];
    }
  }

  has(key) {
    const k = [ this._namespace, key, ].join(this._sep);
    if (!this._store[k]) return [ _notFound(k), ];
    return [ null, true, ];
  }

  del(key) {
    if (key) {
      const k = [ this._namespace, key, ].join(this._sep);
      if (!this._store[k]) return [ _notFound(k), ];

      delete this._store[k];
      return [ null, ];
    }

    Object.keys(window.localStorage).forEach((k) => {
      const ns = k.split(this._sep)[0];
      if (ns === this._namespace) {
        delete this._store[k];
      }
    });
  }

  search(pattern) {
    if (!pattern) {
      throw new Error('A pattern is required');
    }

    const matchKeys = (key) => {
      const [ , _key, ] = key.split(this._sep);

      if (!_key) return;
      if (!pattern.test(_key)) return;

      return key;
    };

    const makePairs = (key) => ({
      key: key.split(this._sep)[1],
      value: this._store[key],
    });

    return [
      null,
      Object.keys(this._store)
        .filter(matchKeys)
        .map(makePairs),
    ];
  }

}

export default new LocalStorage(MEDIA.NAMESPACE);
