// TODO: move all messages to @ezugi/constants
export const TAKE_SEAT = 'TakeSeat';
export const SEAT_TAKEN = 'SeatTaken';
export const LEAVE_SEAT = 'LeaveSeat';
export const PLAYER_TURN = 'PlayerTurn';
export const CALL_BETS = 'CallBets';
export const CALL_BET_DECISION = 'CallBetDecision';
export const CARD_MESSAGE = 'CardMessage';
export const SEAT_RESULT = 'SeatResult';
export const BETTING_CALL = 'BettingCall';
export const PLAYERS_BETS = 'PlayersBets';
export const NO_MORE_BETS = 'NO_MORE_BETS';

export const DEALER_SEAT_ID = 'd';
export const SEAT_SCORE_SEPARATOR = '/';
