import { of, } from 'rxjs';
import {
  assocPath, divide, head, isEmpty, tail, prop, propOr,
} from 'ramda';

import { isNumber, } from '@ezugi/utils';
import playersActions from '../../actions/players';

import { currentBetsSelector, nonDealerSeatsSelector, } from '../../selectors';
import { CARD_TYPES_MAP, } from '../../constants/seats';
import { PLAYER_CALLED_SPLIT, } from '../../constants/decision';
import { isAce, } from '../../../primitives/Card/utils';

/* const {
  betActions: { bet, },
} = bootstrapActions; */

const { seats, } = playersActions;

const splitTotalCardValue = (card) => isAce(prop('cardName', card))
  ? '1/11'
  : isNumber(prop('totalCardValues', card)) // on UBJ auto-split totalCardValues has a string 'SPLIT'
    ? divide(prop('totalCardValues', card), 2) : prop('totalCardValues', card);

export default function handleAutoSplit(socketMessage, state) {
  const { ClientId, SeatId, } = socketMessage;

  const actions = [];
  const seat = nonDealerSeatsSelector(state).find((s) => s.seatId === SeatId);
  const isInactivePlayer = isEmpty(currentBetsSelector(state))
    && isEmpty(propOr([], 'cards', seat))
    && isEmpty(propOr([], 'cardsList', seat));
  const CARDS_UPDATE_STORE = isInactivePlayer ? CARD_TYPES_MAP.Extra : CARD_TYPES_MAP.Relevant;

  const firstCard = head(prop(isInactivePlayer ? 'extraCardsList' : 'cards', seat));
  const lastCard = head(tail(prop(isInactivePlayer ? 'extraCardsList' : 'cards', seat)));
  const splitSeatIndex = `${SeatId}-2`;

  const cardValue = splitTotalCardValue(lastCard);
  const splitHandFirstCard = assocPath([ 'totalCardValues', ], cardValue, firstCard);
  const splitHandSecondCard = assocPath([ 'totalCardValues', ], cardValue, lastCard);

  const firstSeatCard = [ splitHandFirstCard, ];
  const secondSeatCard = [ splitHandSecondCard, ];

  const defaultSplitSeatData = {
    taken: false,
    playerId: ClientId,
    seatId: splitSeatIndex,
    active: false,
    type: 'seat-taken',
    betAmount: 0,
    totalCardValue: propOr(0, 'totalCardValues', splitHandSecondCard),
    betsList: {},
    reservedSeat: false,
    cardsList: [],
    cards: [],
    extraCardsList: [],
  };

  actions.push(...[
    seats.concat({
      ...defaultSplitSeatData,
      ...{
        [CARDS_UPDATE_STORE]: secondSeatCard,
        callBetDecision: PLAYER_CALLED_SPLIT,
      },
    }),
    seats.update({
      ...seat,
      ...{
        seatId: SeatId,
        totalCardValue: propOr(0, 'totalCardValues', splitHandFirstCard),
        [CARDS_UPDATE_STORE]: [
          ...firstSeatCard,
        ],
        callBetDecision: PLAYER_CALLED_SPLIT,
      },
    }),
  ]);

  return of(...actions);
}
