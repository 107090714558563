import {
  append, compose, evolve, path, prop, init, split, join, map, pathOr,
} from 'ramda';
import { mapPropNames, } from '@ezugi/utils';
import humps from 'humps';
import qs from 'qs';

import { getGameParams, parseJson, } from '../../../util/epicsUtils';

const { REACT_APP_API_PORT, REACT_APP_API_PROTOCOL, REACT_APP_API_HOST, REACT_APP_API_AUTHENTICATE, } = process.env;

const PORT = REACT_APP_API_PORT;
const BASE_URL = `${REACT_APP_API_PROTOCOL}://${REACT_APP_API_HOST}:${PORT}`;
const AUTH_URL = `${BASE_URL}${REACT_APP_API_AUTHENTICATE}`;

export const authenticate = (params) => fetch(`${AUTH_URL}?${qs.stringify(params)}`, {
  credentials: 'include',
})
  .then((res) => res.json())
  .catch((err) => Promise.reject(err));

export function getAuthParams({ operatorId, token, }) {
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true, });
  const identifier = `${operatorId}${token}`;

  return { operatorId, token, identifier, ...queryParams, };
}

export const pluckLocale = prop('locale');

export const pluckUserData = compose(
  evolve({
    clientId: compose(
      join('|'),
      append(getGameParams().table_id),
      init,
      split('|')
    ),
  }),
  mapPropNames(humps.camelize),
  prop('user_data')
);

export const pluckWidgets = compose(
  mapPropNames(humps.camelize),
  path([ 'config', 'widgets', ])
);

export const pluckHome = compose(
  mapPropNames(humps.camelize),
  path([ 'config', 'home', ])
);

export const pluckChat = compose(
  mapPropNames(humps.camelize),
  pathOr({}, [ 'config', 'chat', ])
);

export const pluckGeneral = compose(
  mapPropNames(humps.camelize),
  path([ 'config', 'general', ])
);

export const pluckTable = compose(path([ 'tables', getGameParams().table_id, ]));

export const pluckTables = prop('tables');

export const pluckCashier = compose(
  mapPropNames(humps.camelize),
  path([ 'config', 'cashier', ])
);

export const pluckAmountDisplay = compose(
  mapPropNames(humps.camelize),
  path([ 'config', 'amount_display', ])
);

export const pluckBJ = compose(
  mapPropNames(humps.camelize),
  map(parseJson),
);
