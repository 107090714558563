import { ofType, combineEpics, } from 'redux-observable';
import { of, } from 'rxjs';
import {
  isEmpty, pathOr, pipe, values, filter as rFilter,
} from 'ramda';
import { pluck, flatMap, filter, } from 'rxjs/operators';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';

import betActions from '../../actions/bets';
import { PLAYERS_BETS, } from '../seats/constants';
import { currentBetsSelector, } from '../../selectors';
import { getBets, } from '../socket/handleInitialData';
import { getTotalNoOfBets, } from '../bets/utils';
import { NOTIFICATION_MSG, } from '../../constants/notifications';
import playersActions from '../../actions/players';

const {
  socketActions: { socket, },
  notificationActions: { notification, },
} = bootstrapActions;
const { bet, } = betActions;
const { seats, } = playersActions;

function playersBetsEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => [ PLAYERS_BETS, ].includes(MessageType)),
    flatMap(({ ClientId, PlayersBets, }) => {
      const state = state$.value;
      const { seats: playerSeats, } = state.players;
      const { current: userBets, } = currentBetsSelector(state);

      const allBetsList = pipe(
        rFilter(({ BetsList, }) => !isEmpty(BetsList)),
        getBets,
      )(PlayersBets);
      const seatsPayload = playerSeats.map((seat) => {
        const betsList = pathOr(seat.betsList, [ seat.seatId, ], allBetsList);
        return {
          ...seat,
          cardsList: [],
          betsList,
        };
      });
      const myBets = seatsPayload.reduce((acc, { seatId, playerId, betsList, }) => {
        if (playerId === ClientId && !isEmpty(betsList)) {
          return {
            ...acc,
            [seatId]: betsList,
          };
        }
        return acc;
      }, {});
      const actions = [ seats.set(seatsPayload), bet.init(myBets), ];
      if (getTotalNoOfBets(values(userBets)) > getTotalNoOfBets(values(myBets))) {
        actions.push(notification.add({ message: NOTIFICATION_MSG.BET_BELOW_MIN_LIMITS_REMOVED, }));
      }

      return of(seats.set(seatsPayload));
    }),
  );
}

export default combineEpics(
  playersBetsEpic,
);
