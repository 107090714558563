/* eslint-disable no-console */
import { contains, pathOr, } from 'ramda';
import { actions as bootstrapActions, constants, } from '@ezugi/bootstrap';

import {
  userBalanceSelector,
  currentBetsSelector,
  currentLimitsSelector,
  betAutoAdjustSelector,
} from '../selectors';

import { MAIN_BET, } from '../../constants/betTypes';
import { NOTIFICATION_MSG, } from '../constants/notifications';
import * as STATUS from './status';

const {
  dialogActions: { dialog, },
  notificationActions: { notification, },
} = bootstrapActions;
const {
  DIALOG: { LOW_BALANCE_DIALOG, },
} = constants;

export const getSimpleNotificationPayload = ({
  status,
  betAutoAdjust,
  minMainBetLimit,
  maxMainBetLimit,
}) => {
  const payload = (() => {
    switch (status) {
    case STATUS.BETTING_NOT_ALLOWED:
      return { message: NOTIFICATION_MSG.BETTING_NOT_ALLOWED, };
    case STATUS.INVALID_BALANCE:
      return { message: NOTIFICATION_MSG.LOW_BALANCE_MESSAGE, };
    case STATUS.BELOW_INDEX_LIMIT:
    case STATUS.BELOW_TABLE_LIMIT:
      return {
        autoAdjust: false,
        eventName: STATUS.BELOW_TABLE_LIMIT,
        message: NOTIFICATION_MSG.BET_BELOW_INDEX_MIN_LIMIT,
        variables: { minIndexLimit: minMainBetLimit, },
      };
    case STATUS.OVER_INDEX_LIMIT:
      return { message: NOTIFICATION_MSG.BET_OVER_INDEX_MAX_LIMIT_ADJUSTED,
        variables: { maxIndexLimit: maxMainBetLimit, }, };
    case STATUS.OVER_TABLE_LIMIT:
      return betAutoAdjust
        ? {
          autoAdjust: true,
          eventName: STATUS.OVER_TABLE_LIMIT,
          message: NOTIFICATION_MSG.BET_OVER_TABLE_MAX_LIMIT_ADJUSTED,
        }
        : { message: NOTIFICATION_MSG.BET_OVER_TABLE_MAX_LIMIT, variables: { maxTableLimit: maxMainBetLimit, }, };
    default:
      return null;
    }
  })();
  return payload;
};

export const validateLimits = (bet, state, type) => {
  const balance = userBalanceSelector(state);
  const betAutoAdjust = betAutoAdjustSelector(state);

  const currentBets = currentBetsSelector(state);
  const existingBet = pathOr(0, [ bet.index, type, 'value', ], currentBets);

  let status = STATUS.VALID;

  let ok = status === STATUS.VALID;
  let { value, } = bet;

  // table limits
  const {
    Max_Bet: maxMainBetLimit,
    Min_Bet: minMainBetLimit,
    Max_SideBet: maxSideBetLimit,
    Min_SideBet: minSideBetLimit,
  } = currentLimitsSelector(state);

  const isMainBet = type === MAIN_BET;
  const minLimit = isMainBet ? minMainBetLimit : minSideBetLimit;
  const maxLimit = isMainBet ? maxMainBetLimit : maxSideBetLimit;
  const totalBet = existingBet;

  if (totalBet + value > maxLimit) {
    status = STATUS.OVER_TABLE_LIMIT;
    ok = betAutoAdjust;
    value = betAutoAdjust ? maxLimit - totalBet : 0;
  }

  if (totalBet + value < minLimit) {
    status = STATUS.BELOW_TABLE_LIMIT;
    ok = false;
  }

  if (value > balance - totalBet) {
    status = STATUS.INVALID_BALANCE;
    ok = false;
    value = 0;
  }

  const valid = !contains(status, [ STATUS.INVALID_BALANCE, STATUS.BETTING_NOT_ALLOWED, ]);
  const notificationMsg = getSimpleNotificationPayload({
    status,
    betAutoAdjust,
    minMainBetLimit,
    maxMainBetLimit,
  });

  const actions = [
    ...(status === STATUS.INVALID_BALANCE ? [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ] : []),
    ...(notificationMsg ? [ notification.add(notificationMsg), ] : []),
  ];

  return {
    ok,
    valid,
    status,
    bet: {
      ...(value && {
        ...bet,
        value,
        valid: ok,
      }),
    },
    actions,
  };
};
