import { isEmpty, } from 'ramda';
import { actions as bootstrapActions, constants, } from '@ezugi/bootstrap';

import { anteRangesSelector, currentBetsSelector, userBalanceSelector, } from '../selectors';
import {
  extractBetValue, getAnteBetValue, getCurrSeatMainBetValue, getTotalBet,
} from '../epics/bets/utils';
import { INVALID_BALANCE, VALID, } from './status';

const {
  dialogActions: { dialog, },
} = bootstrapActions;
const {
  DIALOG: { LOW_BALANCE_DIALOG, },
} = constants;

const ZERO_ANTE = 0;

/**
@param {Object} bet - {index: "s4", value: 1, valid: false}
 */
export const validateAnteRanges = (bet, state) => {
  const balance = userBalanceSelector(state);
  const currentBets = currentBetsSelector(state);
  const totalBet = getTotalBet(currentBets);
  const anteRanges = anteRangesSelector(state);
  const betValue = extractBetValue(bet);
  const currSeatMainBet = getCurrSeatMainBetValue(bet, currentBets);
  // currSeatMainBet is ZERO if there was no previous mainBet
  const oldAnteValue = currSeatMainBet ? getAnteBetValue(currSeatMainBet, anteRanges) : ZERO_ANTE;
  const newAnteValue = getAnteBetValue(Number(currSeatMainBet + betValue), anteRanges);

  // bet isEmpty when placed bet is OVER_TABLE_LIMIT
  const ok = !isEmpty(bet)
    // totalBet already contains oldAnteValue
    ? balance - totalBet - (newAnteValue - oldAnteValue) >= betValue
    : true;
  const status = ok ? VALID : INVALID_BALANCE;
  const actions = !ok ? [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ] : [];
  return {
    ok,
    valid: ok,
    status,
    bet,
    actions,
  };
};
