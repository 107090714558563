import {
  add,
  head,
  contains,
  reduce,
  prop,
  all,
  merge,
  compose,
  map,
  reject,
  isNil,
  flatten,
  values,
  isEmpty,
  unless,
  last,
  pipe,
  toPairs,
  path,
  gte,
  any,
  hasIn,
  filter,
  either,
  fromPairs,
  mergeDeepRight,
  pathOr,
  pick,
  keys,
} from 'ramda';
import { actions as bootstrapActions, constants, } from '@ezugi/bootstrap';

import {
  VALID, BETTING_NOT_ALLOWED, INVALID_BALANCE, OVER_TABLE_LIMIT, BELOW_TABLE_LIMIT, BELOW_INDEX_LIMIT, OVER_INDEX_LIMIT,
} from './status';
import { NOTIFICATION_MSG, } from '../constants/notifications';
import { validateRoundStatus, } from './round';
import { validateSeatOwner, } from './owner';
import { validateBalance, } from './balance';
import { validateLimits, } from './limits';
import { validateSeatHasMainBet, } from './seatHasMainBet';
import { validateAnteRanges, } from './anteRanges';
import {
  ANTE_BET, MAIN_BET, TWENTY_ONE, PERFECT_PAIR,
} from '../../constants/betTypes';
import betActions from '../actions/bets';
import { INITIAL_STATE, } from '../reducers/bets';
import {
  anteConfigObjSelector,
  userBalanceSelector,
  lastBetsSelector,
  currentLimitsSelector,
  callBetsSelector,
  totalBetSelector,
  currentBetsSelector,
  earlyDecisionSeatSelector,
  playerTurnSelector,
  canPlaceBetsSelector,
} from '../selectors';

import { extractBetValue, getAnteBetValue, getCurrSeatMainBetValue, } from '../epics/bets/utils';

const {
  LOW_BALANCE_DIALOG,
} = constants;
const {
  dialogActions: { dialog, },
  notificationActions: { notification, },
} = bootstrapActions;
// const { notification, } = notificationActions;

const RESULT_SEED = {
  ok: true,
  valid: true,
  status: VALID,
  actions: [],
};
const statusPriorityMap = {
  [BETTING_NOT_ALLOWED]: 4,
  [INVALID_BALANCE]: 3,
  [OVER_TABLE_LIMIT]: 2,
  [BELOW_TABLE_LIMIT]: 1,
  [VALID]: 0,
};

const pipeValidations = (validations) => (bet, state, type) => reduce(
  (res, fn) => {
    const validation = fn(res.bet, state, type);
    const resultValidation = (contains(res.status, [
      BELOW_TABLE_LIMIT,
      BELOW_INDEX_LIMIT,
      OVER_TABLE_LIMIT,
      OVER_INDEX_LIMIT,
      BETTING_NOT_ALLOWED,
      INVALID_BALANCE,
    ]) && validation.status !== INVALID_BALANCE) ? { ...validation, ...{ actions: res.actions, }, } : validation;
    return resultValidation;
  },
  {
    ...RESULT_SEED,
    bet,
  },
  validations
);

const isOk = prop('ok');
const isValid = prop('valid');
const double = (value) => value * 2;

const validateAndAdjust = ({ bets, results, type, state, }) => {
  const status = compose(
    reduce((prev, next) => {
      const prevPriority = statusPriorityMap[prev];
      const nextPriority = statusPriorityMap[next];

      return nextPriority > prevPriority ? next : prev;
    }, VALID),
    map(prop('status'))
  )(results);

  const ok = all(isOk, results);
  const valid = all(isValid, results) && !contains(status, [ INVALID_BALANCE, BETTING_NOT_ALLOWED, ]);

  const isMainBet = type === MAIN_BET;
  const currentBets = currentBetsSelector(state);
  const { isAnteBetEnabled, anteRanges, } = anteConfigObjSelector(state);

  return {
    ok,
    valid,
    status,
    bets: valid
      ? compose(
        reduce(merge, {}),
        map(
          compose(
            unless(isEmpty, (bet) => ({ [bet.index]: {
              [type]: bet, // user chosen bet
              ...(isMainBet && isAnteBetEnabled
                ? { // add ANTE bet if user added a MAIN bet
                  [ANTE_BET]: {
                    ...bet,
                    value: getAnteBetValue(getCurrSeatMainBetValue(bet, currentBets) + extractBetValue(bet),
                      anteRanges),
                  },
                }
                : {}),
            }, })),
            prop('bet')
          )
        )
      )(results)
      : null,
    initialBets: bets,
    actions: compose(
      flatten,
      map(
        compose(
          reject(
            compose(
              isNil,
              prop('payload')
            )
          ),
          prop('actions')
        )
      )
    )(results),
  };
};

export function validateBets(bets, state, type) {
  const isMainBet = type === MAIN_BET;
  const { isAnteBetEnabled, } = anteConfigObjSelector(state);
  // construct validation steps for each bet, validation order must be respected
  const validations = [
    validateRoundStatus,
    validateSeatOwner,
    ...(!isMainBet ? [ validateSeatHasMainBet, ] : []),
    validateLimits,
    validateBalance,
    ...(isMainBet && isAnteBetEnabled ? [ validateAnteRanges, ] : []), // validate ANTE bet
  ];

  const results = values(bets).map((bet) => pipeValidations(validations)(bet, state, type));
  return validateAndAdjust({ bets, results, type, state, });
}

export function validateBetUndo(_, state) {
  const h = [ ...state.bets.history, ];
  h.pop();

  const s = {
    ...(last(h) || INITIAL_STATE),
  };
  const b = userBalanceSelector(state);

  const ok = s.totalBet <= b;

  return {
    ok,
    actions: ok ? [ betActions.history.apply({ ...s, history: h, }), ] : [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ],
  };
}

export function validateRebet(_, state) {
  const lb = lastBetsSelector(state);
  const b = userBalanceSelector(state);

  const ok = lb.totalBet <= b;

  return {
    ok,
    actions: ok ? [ betActions.bet.apply(lb), ] : [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ],
  };
}

// will hold the total value of user balance against total bet
let defaultRemainder = 0;

/**
 * the bet is valid if the double is smaller than remainder and the total max limit or\
 * if the difference between current value and max is smaller than 1/2 * total limit and we have existing balance
 */
const doubleCurrentBet = ({ bet, limits: { min, max, }, type, }) => {
  let { value, } = bet;
  let valid = true;
  let actions = [];

  if (bet.value <= defaultRemainder && double(bet.value) < max) {
    value = double(value) < max ? double(value) : max;
    valid = value >= min;
    actions = valid ? [] : [ notification.add({ message: NOTIFICATION_MSG.BETTING_NOT_ALLOWED, }), ];
    defaultRemainder = valid ? defaultRemainder - (value - bet.value) : defaultRemainder;
  } else if (max > bet.value && defaultRemainder >= max - bet.value) {
    value = max;
    defaultRemainder = valid ? defaultRemainder - (max - bet.value) : defaultRemainder;
  }

  return { [type]: { ...bet, value, valid, actions, }, };
};

const doubleSeatBets = (bets, {
  minMainBet,
  maxMainBet,
  minSideBet,
  maxSideBet,
}, type) => pipe(
  toPairs,
  filter((item) => item[0] === type),
  map((bet) => doubleCurrentBet({
    bet: bet[1],
    limits: bet[0] === MAIN_BET
      ? { min: minMainBet, max: maxMainBet, }
      : { min: minSideBet, max: maxSideBet, },
    type: bet[0],
  })),
  reduce((acc, curr) => ({ ...acc, ...curr, }), {}),
)(bets);

const getDoubleAddedVal = (max, bet) => bet === max ? 0 : double(bet) < max ? bet : max - bet;

export const isDoubleValid = (allBets, { main, side, }, rem,
  types = [ MAIN_BET, TWENTY_ONE, PERFECT_PAIR, ], anteObj) => (
  pipe(
    values,
    map(pick(types)),
    reduce(
      (acc, curr) => acc + getDoubleAddedVal(main, pathOr(0, [ types[0], 'value', ], curr)) // MAIN_BET
          + (getDoubleAddedVal(side, pathOr(0, [ types[1], 'value', ], curr)) || 0) // TWENTY_ONE
          + (getDoubleAddedVal(side, pathOr(0, [ types[2], 'value', ], curr)) || 0), // PERFECT_PAIR
      0,
    ),
    (valueForDouble) => { // if isAnteBetEnabled than check also for ANTE bet whenever a MAIN bet is placed
      const { isAnteBetEnabled, anteRanges, } = anteObj;
      let nextAnteBetsTotal = 0;
      if (isAnteBetEnabled && types.includes(MAIN_BET)) {
        const placedMainBets = pipe(
          values,
          map(pathOr(0, [ MAIN_BET, 'value', ])),
        )(allBets);
        const nextAnteBets = map(
          (placedMainBet) => getAnteBetValue(placedMainBet + getDoubleAddedVal(main, placedMainBet), anteRanges)
        )(placedMainBets);
        nextAnteBetsTotal = reduce(add, 0)(nextAnteBets);
      }
      return valueForDouble + nextAnteBetsTotal;
    },
    gte(rem), // returns true if rem is greater than or equal to the double value; false otherwise.
  )(allBets)
);

const doubleBetsByType = ({ type, state, }) => map((seatBet) => {
  const {
    Min_Bet: minMainBet,
    Min_SideBet: minSideBet,
    Max_Bet: maxMainBet,
    Max_SideBet: maxSideBet,
  } = currentLimitsSelector(state);
  const limits = { minMainBet, maxMainBet, minSideBet, maxSideBet, };
  const { seatId, } = callBetsSelector(state);

  if (seatId && seatBet.main.index !== seatId) {
    return { ...seatBet, };
  }
  const filteredSeat = pipe(
    toPairs,
    filter((item) => item[0] === type),
    fromPairs,
  )(seatBet);

  return merge(filteredSeat, doubleSeatBets(seatBet, limits, type));
})(currentBetsSelector(state));

export const hasSideBets = pipe(
  values,
  either(any(hasIn(TWENTY_ONE)), any(hasIn(PERFECT_PAIR))),
);

export const hasAnteBet = pipe(
  values,
  either(any(hasIn(ANTE_BET))),
);

const getBetLimits = ({ Max_Bet: main, Max_SideBet: side, }) => ({ main, side, });

const doubleCurrentBets = (state) => {
  const canPlaceBets = canPlaceBetsSelector(state);
  const totalBet = totalBetSelector(state);
  const balance = userBalanceSelector(state);
  const currBets = currentBetsSelector(state);
  const betLimits = getBetLimits(currentLimitsSelector(state));
  const anteObj = anteConfigObjSelector(state);
  const { isAnteBetEnabled, anteRanges, } = anteObj;

  defaultRemainder = balance - totalBet;

  // if main can be doubled, double all of them
  let doubledMainBets = isDoubleValid(currBets, betLimits, defaultRemainder, [ MAIN_BET, ], anteObj)
    ? doubleBetsByType({ type: MAIN_BET, state, })
    : map((item) => ({ main: item[MAIN_BET], }))(currBets);

  // recompute ANTE bets according the the new doubled main bets
  if (isAnteBetEnabled && canPlaceBets && hasAnteBet(currBets)) {
    doubledMainBets = pipe(
      toPairs,
      map((item) => ([ head(item), {
        ...last(item), // {"main": {"index": "s4", "valid": true, "value": 300}}
        [ANTE_BET]: {
          ...pathOr({}, [ MAIN_BET, ], last(item)),
          value: getAnteBetValue(pathOr(0, [ MAIN_BET, 'value', ], last(item)), anteRanges),
        },
      }, ])),
      fromPairs,
    )(doubledMainBets);
  }

  // check for sideBets
  if (hasSideBets(currBets) && canPlaceBets
    && isDoubleValid(currBets, betLimits, defaultRemainder, [ TWENTY_ONE, PERFECT_PAIR, ], anteObj)) {
    const doubled21 = doubleBetsByType({ state, type: TWENTY_ONE, });
    const doubledPP = doubleBetsByType({ state, type: PERFECT_PAIR, });

    return mergeDeepRight(doubledMainBets, mergeDeepRight(doubled21, doubledPP));
  }
  return mergeDeepRight(currBets, doubledMainBets);
};

export const getRemainder = (balance, totalBet) => balance - totalBet;

export function validateDouble(state) {
  const { seatId, } = callBetsSelector(state);
  const currentBets = currentBetsSelector(state);

  // for NMB state we double only the selected main bet and move on
  if (seatId && keys(currentBets).includes(seatId)) {
    const mainBetDoubleValue = double(currentBets[seatId].main.value);
    const actions = [ betActions.bet.apply({
      current: {
        ...currentBets,
        [seatId]: {
          ...currentBets[seatId],
          main: {
            ...currentBets[seatId].main,
            value: mainBetDoubleValue,
          },
        },
      },
    }), ];
    return { actions, };
  }
  const totalBet = totalBetSelector(state);
  const userBalance = userBalanceSelector(state);
  const earlyDecisionSeat = earlyDecisionSeatSelector(state);
  const playerTurn = playerTurnSelector(state);
  const anteObj = anteConfigObjSelector(state);

  const playerBets = playerTurn.seatId
    ? path([ playerTurn.seatId, MAIN_BET, 'value', ], currentBets)
    : path([ earlyDecisionSeat, MAIN_BET, 'value', ], currentBets);

  const bets = playerBets || double(totalBet);
  let status = bets > userBalance ? INVALID_BALANCE : VALID;

  if (!playerBets) {
    const rem = getRemainder(userBalance, totalBet);
    const betLimits = getBetLimits(currentLimitsSelector(state));
    status = (isDoubleValid(currentBets, betLimits, rem, [ MAIN_BET, ], anteObj)
      || isDoubleValid(currentBets, betLimits, rem, [ TWENTY_ONE, PERFECT_PAIR, ], anteObj))
      ? VALID : INVALID_BALANCE;
  }

  const actions = (status === VALID)
    ? [ betActions.bet.apply({ current: doubleCurrentBets(state), }), ]
    : [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ];
  return {
    actions,
  };
}
