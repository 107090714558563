import { contains, } from 'ramda';
import { validMainBetsKeysSelector, } from '../selectors';
import { BETTING_NOT_ALLOWED, VALID, } from './status';

export const validateSeatHasMainBet = (bet, state) => {
  const validMainBetsKeys = validMainBetsKeysSelector(state);
  const ok = contains(bet.index, validMainBetsKeys);
  return {
    ok,
    bet: ok ? bet : {},
    valid: ok,
    status: ok ? VALID : BETTING_NOT_ALLOWED,
    actions: [],
  };
};
