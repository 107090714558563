export const VALID = 'VALID';
export const INVALID_BALANCE = 'INVALID_BALANCE';
export const BELOW_TABLE_LIMIT = 'BELOW_TABLE_LIMIT';
export const OVER_TABLE_LIMIT = 'OVER_TABLE_LIMIT';
export const BELOW_INDEX_LIMIT = 'BELOW_INDEX_LIMIT';
export const OVER_INDEX_LIMIT = 'OVER_INDEX_LIMIT';
export const BETTING_NOT_ALLOWED = 'BETTING_NOT_ALLOWED';
export const ADJUST_BET_TO_BALANCE = 'ADJUST_BET_TO_BALANCE';
export const ADJUST_BET_TO_INDEX_LIMIT = 'ADJUST_BET_TO_INDEX_LIMIT';
export const ADJUST_BET_TO_TABLE_LIMIT = 'ADJUST_BET_TO_TABLE_LIMIT';
