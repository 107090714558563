import bowser from 'bowser';
import qs from 'qs';

import { DEVICE, } from '@ezugi/constants';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';

import errorActions from '../../actions/error';

import { CODES, TABLE_IS_CLOSED, } from '../../constants/error';

const {
  TYPE: { TABLET, SMARTPHONE, HTML, },
} = DEVICE;
const { notificationActions, } = bootstrapActions;

export const getClientType = () => (bowser.tablet && TABLET) || (bowser.mobile && SMARTPHONE) || HTML;

export const pluckSocketParams = (state) => {
  const {
    config: { definitions, authParams, },
    user,
    game,
    /* eslint-disable no-shadow */
  } = state;
  const url = definitions.SERVER_URL_GAMES;
  const ClientId = `${user.operatorId}|${user.uid}|${authParams.table_id}`;
  const params = {
    MessageType: 'INITIALIZE_PLAYER',
    TableId: authParams.table_id,
    LimitId: game.limitId,
    SessionCurrency: user.currency,
    CurrentPlayerToken: user.currentPlayerToken,
    destination: 'gameEngine',
    gameType: game.serverName,
    Language: 'ENGLISH',
    ClientIp: user.clientIp,
    ClientId,
    Nickname: user.nickname,
    OperatorID: user.operatorId,
    clientType: getClientType(),
  };

  return {
    url,
    params,
  };
};

const { error, } = errorActions;
const { notification, } = notificationActions;

export const getPayload = (code) => {
  switch (true) {
  case code === 30037: {
    return {
      code,
    };
  }
  case code === 30055: {
    return {
      code: CODES[TABLE_IS_CLOSED],
    };
  }
  case code >= 30000 && code < 60000: {
    return { message: 'messages.bets_not_placed_message', };
  }
  default: {
    return {
      code,
      title: `errors.unknown_${code}`,
      description: `errors.unknown_${code}`,
    };
  }
  }
};

export const getActions = (code) => {
  const fatal = [ 30037, 30055, ];
  if (fatal.includes(code)) return error.set(getPayload(code));
  if (code >= 30000 && code < 60000) return notification.add(getPayload(code));

  return error.set(getPayload());
};

const { game_id: gameId, } = qs.parse(location.search, { ignoreQueryPrefix: true, });

export const isBJonURL = gameId === '1';
export const isABJonURL = gameId === '10';
export const isUBJonURL = gameId === '12';
