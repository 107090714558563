/* eslint-disable no-shadow */
import { of, } from 'rxjs';
import { first, flatMap, switchMap, } from 'rxjs/operators';
import { ofType, } from 'redux-observable';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';

import dealNowActions from '../../actions/dealNow';
import {
  authSelector,
  gameSelector,
  playerSeatsSelector,
  tableIdSelector,
  userSelector,
} from '../../selectors';


const { dealNow, } = dealNowActions;
const { socketActions: { socket, }, } = bootstrapActions;

const dealNowRequestEpic = (action$, state$) => action$.pipe(
  ofType(dealNow.request),
  switchMap(() => state$.pipe(first())),
  flatMap((state) => {
    const { seats, } = playerSeatsSelector(state);
    const user = userSelector(state) || {};
    const authParams = authSelector(state) || {};
    const tableId = tableIdSelector(state);
    const game = gameSelector(state) || {};

    const actions = seats.map(({ seatId, }) => socket.send({
      MessageType: 'DealNow',
      ClientId: `${user.operatorId}|${user.uid}|${authParams.table_id}`,
      SeatId: seatId,
      TableId: tableId,
      destination: 'table',
      gameType: game.serverName,

    }));

    return of(...actions);
  }),
);


export default dealNowRequestEpic;
