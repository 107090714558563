import { map, flatMap, filter, } from 'rxjs/operators';
import { ofType, combineEpics, } from 'redux-observable';
import { isEmpty, isNil, } from 'ramda';
import { concat, of, } from 'rxjs';
import { LAST_BETS, PLACE_YOUR_BETS, } from '@ezugi/constants';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';

import ls from '../../localstorage';
import gameActions from '../actions/game';
import { GAME, } from '../../constants';
import {
  freeSeatsSelector,
  gameSettingsSelector,
  roundStatusSelector,
  maxNoOfSeatsPerPlayerSelector,
  playerSeatsIdsSelector,
  hasBalanceToPlaceBetSelector,
} from '../selectors';
import { NOTIFICATION_MSG, } from '../constants/notifications';

const { settings, greeting, } = gameActions;
const {
  notificationActions: { notification, },
} = bootstrapActions;

const settingsEpic = (action$, state$) => action$.pipe(
  ofType(settings.apply),
  map(({ payload, }) => {
    const currentSettings = gameSettingsSelector(state$.value);
    const [ err, ] = ls.put(GAME.SETTINGS, { ...currentSettings, ...payload, });
    return err ? settings.failure() : null;
  }),
  filter((res) => res),
);

const greetingNotificationEpic = (action$, state$) => action$.pipe(
  ofType(greeting.request),
  flatMap(() => {
    const roundStatus = roundStatusSelector(state$.value);
    const maxNoOfSeatsPerPlayer = maxNoOfSeatsPerPlayerSelector(state$.value);
    const playerSeats = playerSeatsIdsSelector(state$.value);
    const tableFreeSeats = freeSeatsSelector(state$.value);
    const hasBalanceToPlaceBet = hasBalanceToPlaceBetSelector(state$.value);
    const playerCanTakeSeat = (tableFreeSeats.length > 0) && (playerSeats.length < maxNoOfSeatsPerPlayer);
    const isPlaceBetsRound = [
      PLACE_YOUR_BETS,
      LAST_BETS,
    ].includes(roundStatus);
    let greetingMessage = null;

    // table is full, player cannot take a seat at the table
    if (hasBalanceToPlaceBet && isEmpty(playerSeats) && !playerCanTakeSeat) {
      greetingMessage = NOTIFICATION_MSG.WAIT_FOR_NEXT_GAME;
    }

    // invite player to take a seat at the table if there are free seats available
    if (isNil(greetingMessage) && hasBalanceToPlaceBet && isEmpty(playerSeats) && playerCanTakeSeat) {
      greetingMessage = isPlaceBetsRound ? NOTIFICATION_MSG.TAKE_SEAT_AND_PLACE_BET : NOTIFICATION_MSG.TAKE_SEAT;
    }

    // invite player to wait for next next game or to take additional seats at the table
    if (isNil(greetingMessage) && hasBalanceToPlaceBet && !isPlaceBetsRound) {
      greetingMessage = !playerCanTakeSeat
        ? NOTIFICATION_MSG.WAIT_FOR_NEXT_GAME
        : NOTIFICATION_MSG.TAKE_ADDITIONAL_SEAT_AND_WAIT_FOR_NEXT_GAME;
    }

    return concat(
      of(greeting.set(true)),
      isNil(greetingMessage) ? [] : of(notification.add({ message: greetingMessage, }))
    );
  }),
);

export default combineEpics(settingsEpic, greetingNotificationEpic);
