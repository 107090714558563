export const DEFAULT = 'default';
export const PORTRAIT = 'portrait';
export const LANDSCAPE = 'landscape';

export const MOBILE_HEADER_HEIGHT_PORTRAIT = 30; // px
export const MOBILE_HEADER_HEIGHT_LANDASCAPE = 20; // px
export const MOBILE_FOOTER_HEIGHT_PORTRAIT = 40; // px
export const MOBILE_BET_TOOLS_PORTRAIT = 40;
export const FOOTER_HEIGHT = 30; // px
export const BET_TOOLBAR_PORTRAIT_HEIGHT = 0; // px
// export const
