import {
  path, pathOr, propOr, prop, map, split, join, init, pipe, isNil, adjust, ifElse, always, head, values,
} from 'ramda';
import df from 'dateformat';
import { createSelector, } from 'reselect';

import { gameSelector, userBalanceSelector, } from './game';

const MAX_NUMBER_CHIPS = 7;

const convertUtcToLocalHour = (h) => {
  const now = new Date();
  now.setUTCHours(+h);
  return df(now, 'HH');
};


export const tableSelector = propOr({}, 'table');
export const tableNameSelector = path([ 'table', 'title', ]);
export const dealerNameSelector = path([ 'table', 'dealer_name', ]);
export const tableLimitsSelector = path([ 'table', 'limits', ]);
export const tableIdSelector = path([ 'table', 'id', ]);

export const tableOpenHoursSelector = pipe(
  pathOr({}, [ 'table', 'open_hours', ]),
  map(
    ifElse(
      isNil,
      always(null),
      pipe(
        split(':'),
        init,
        adjust(convertUtcToLocalHour, 0),
        join(':')
      )
    )
  )
);

/**
 * Selector for the current limits of the table
 */
export const currentLimitsSelector = createSelector(
  tableLimitsSelector,
  gameSelector,
  (limits, game) => limits[game.limitId] || head(values(limits))
);

export const minBetSelector = createSelector(currentLimitsSelector, propOr(0, 'Min_Bet'));
export const maxBetSelector = createSelector(currentLimitsSelector, prop('Max_Bet'));

/**
 * Selector for the chips based on current limits
 * Display only the first 6 chips
 */
export const chipsSelector = createSelector(currentLimitsSelector, (limits) => limits.chips.slice(0, MAX_NUMBER_CHIPS));

/**
 * Selector for the chips based on current limits
 * Display only the first 6 chips
 */
export const validatedChipsSelector = createSelector(
  // eslint hax
  chipsSelector,
  userBalanceSelector,
  (chips, balance) => chips.map((chip) => ({
    ...chip,
    disabled: balance < chip.value,
  }))
);

export const anteRangesSelector = pathOr({}, [ 'table', 'anteRanges', ]);
