import {
  pipe, defaultTo, filter, isNil, isEmpty,
} from 'ramda';
import { of, } from 'rxjs';

import { actions as bootstrapActions, } from '@ezugi/bootstrap';

import { getBets, } from './handleInitialData';

const {
  notificationActions: { notification, },
  betActions: { bet, },
} = bootstrapActions;


const handleOperatorError = (socketMessage) => {
  const { SeatBets, } = socketMessage;

  const bets = pipe(
    defaultTo([]),
    filter((seat) => !isNil(seat.BetsList) && !isEmpty(seat.BetsList)),
    getBets,
  )(SeatBets);

  return of(
    isEmpty(bets) ? bet.reset() : bet.apply({ current: bets, }),
    notification.add({ message: 'messages.bet_too_late', })
  );
};

export default handleOperatorError;
