export const UNKNOWN_ERROR = 'UNKNOWN_ERROR';
export const UNKNOWN_GAME = 'UNKNOWN_GAME';
export const INACTIVE_OPERATOR = 'INACTIVE_OPERATOR';
export const MISSING_AUTH_PARAMS = 'MISSING_AUTH_PARAMS';
export const FAILED_TO_CALL_GAME_ENGINE = 'FAILED_TO_CALL_GAME_ENGINE';
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';
export const PLAYER_BLOCKED = 'PLAYER_BLOCKED';
export const COUNTRY_BLOCKED = 'COUNTRY_BLOCKED';
export const AUTHENTICATION_REQUIRED_FOR_THIS_ACTION = 'AUTHENTICATION_REQUIRED_FOR_THIS_ACTION';
export const MISSING_REQUIRED_PARAMS = 'MISSING_REQUIRED_PARAMS';
export const NO_ACTIVE_TABLES_FOR_THIS_GAME = 'NO_ACTIVE_TABLES_FOR_THIS_GAME';
export const SYSTEM_IN_MAINTENANCE = 'SYSTEM_IN_MAINTENANCE';
export const BLACKLISTED_IP = 'BLACKLISTED_IP';
export const FAILED_TO_GET_TABLES_LIST = 'FAILED_TO_GET_TABLES_LIST';
export const FAILED_TO_GET_CONFIG = 'FAILED_TO_GET_CONFIG';
export const UNKNOWN_TABLE = 'UNKNOWN_TABLE';
export const UNKNOWN_LIMIT = 'UNKNOWN_LIMIT';
export const FAILED_GETTING_LEGACY_SESSION = 'FAILED_GETTING_LEGACY_SESSION';
export const AUTHENTICATION_REQUIRED_PARAMS_FOR_LEGACY_GAME = 'AUTHENTICATION_REQUIRED_PARAMS_FOR_LEGACY_GAME';
export const BO_AUTHENTICATION_FAILED = 'BO_AUTHENTICATION_FAILED';
export const FAILED_REGISTERING_CHAT_USER = 'FAILED_REGISTERING_CHAT_USER';
export const FAILED_TO_GET_SKIN = 'FAILED_TO_GET_SKIN';
export const GAME_NOT_SUPPORTED_FOR_DEVICE = 'GAME_NOT_SUPPORTED_FOR_DEVICE';
export const TABLE_IS_CLOSED = 'TABLE_IS_CLOSED';
export const BAD_INTERNET_CONNECTION = 'BAD_INTERNET_CONNECTION';

export const CODES = {
  100: UNKNOWN_ERROR,
  101: UNKNOWN_GAME,
  102: INACTIVE_OPERATOR,
  103: MISSING_AUTH_PARAMS,
  104: FAILED_TO_CALL_GAME_ENGINE,
  105: AUTHENTICATION_FAILED,
  106: PLAYER_BLOCKED,
  107: COUNTRY_BLOCKED,
  108: AUTHENTICATION_REQUIRED_FOR_THIS_ACTION,
  109: MISSING_REQUIRED_PARAMS,
  110: NO_ACTIVE_TABLES_FOR_THIS_GAME,
  111: SYSTEM_IN_MAINTENANCE,
  112: BLACKLISTED_IP,
  113: FAILED_TO_GET_TABLES_LIST,
  114: FAILED_TO_GET_CONFIG,
  115: UNKNOWN_TABLE,
  116: UNKNOWN_LIMIT,
  117: FAILED_GETTING_LEGACY_SESSION,
  118: AUTHENTICATION_REQUIRED_PARAMS_FOR_LEGACY_GAME,
  119: BO_AUTHENTICATION_FAILED,
  120: FAILED_REGISTERING_CHAT_USER,
  201: FAILED_TO_GET_SKIN,
  202: GAME_NOT_SUPPORTED_FOR_DEVICE,
  203: TABLE_IS_CLOSED,
  208: BAD_INTERNET_CONNECTION,
  [UNKNOWN_ERROR]: 100,
  [UNKNOWN_GAME]: 101,
  [INACTIVE_OPERATOR]: 102,
  [MISSING_AUTH_PARAMS]: 103,
  [FAILED_TO_CALL_GAME_ENGINE]: 104,
  [AUTHENTICATION_FAILED]: 105,
  [PLAYER_BLOCKED]: 106,
  [COUNTRY_BLOCKED]: 107,
  [AUTHENTICATION_REQUIRED_FOR_THIS_ACTION]: 108,
  [MISSING_REQUIRED_PARAMS]: 109,
  [NO_ACTIVE_TABLES_FOR_THIS_GAME]: 110,
  [SYSTEM_IN_MAINTENANCE]: 111,
  [BLACKLISTED_IP]: 112,
  [FAILED_TO_GET_TABLES_LIST]: 113,
  [FAILED_TO_GET_CONFIG]: 114,
  [UNKNOWN_TABLE]: 115,
  [UNKNOWN_LIMIT]: 116,
  [FAILED_GETTING_LEGACY_SESSION]: 117,
  [AUTHENTICATION_REQUIRED_PARAMS_FOR_LEGACY_GAME]: 118,
  [BO_AUTHENTICATION_FAILED]: 119,
  [FAILED_REGISTERING_CHAT_USER]: 120,
  [FAILED_TO_GET_SKIN]: 201,
  [GAME_NOT_SUPPORTED_FOR_DEVICE]: 202,
  [TABLE_IS_CLOSED]: 203,
  [BAD_INTERNET_CONNECTION]: 208,
};
