import { keyframes, } from 'emotion';
import { css, } from 'react-emotion';

export const ANIMATION_DURATION = 300; // ms
/**
 * Used to reset the Timer animations
 * Value `0`, it shouldn't impact the player decision time
 */
export const TIMER_DELAY = 0; // ms

export const slideInLeft = keyframes`
  from {
    transform: translateX(-50vw);
  }
  to {
    transform: translateX(0);
  }
`;

export const slideOutLeft = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50vw);
  }
`;

export const slitInVerticalAnimation = keyframes`
  0% {
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    transform: translateZ(0) rotateY(0);
  }
`;

export const slitInVerticalRotateAnimation = keyframes`
  0% {
    transform: translateZ(-800px) rotateY(90deg) rotateZ(0);
    opacity: 0;
  }
  54% {
    transform: translateZ(-160px) rotateY(87deg) rotateZ(5deg);
    opacity: 1;
  }
  100% {
    transform: translateZ(0) rotateY(-10deg) rotateZ(10deg);
  }
`;

export const fadeInTopAnimation = keyframes`
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const fadeOutTopAnimation = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50px);
    opacity: 0;
  }
`;


export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const slideInLeftClassName = css`
  animation: ${slideInLeft} ${ANIMATION_DURATION}ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
`;

export const slideOutLeftClassName = css`
  animation: ${slideOutLeft} ${ANIMATION_DURATION}ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
`;

export const slitInVerticalClassName = css`
  animation: ${slitInVerticalAnimation} ${ANIMATION_DURATION}ms ease-out both;
`;

export const slitInVerticalRotateClassName = css`
  animation: ${slitInVerticalRotateAnimation} ${ANIMATION_DURATION}ms ease-out both;
`;

export const fadeInTopAnimClassName = css`
  animation: ${fadeInTopAnimation} ${ANIMATION_DURATION}ms cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
`;

export const fadeOutTopAnimClassName = css`
  animation: ${fadeOutTopAnimation} ${ANIMATION_DURATION}ms cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
`;

export const fadeOutAnimClassName = css`
  animation: ${fadeIn} ${ANIMATION_DURATION}ms ease-out both reverse;
`;
