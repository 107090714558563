import {
  assoc, props, compose, any, pipe,
} from 'ramda';
import qs from 'qs';
import bowser from 'bowser';

import { isNotNil, } from './logic';

export const isHandheld = pipe(
  props([ 'mobile', 'tablet', ]),
  any(isNotNil),
)(bowser);
export const mobileDetector = compose(
  assoc('isHandheld', isHandheld),
)(bowser);

// if orientation is landscape true, otherwise false
export const getOrientation = () => window.innerHeight < window.innerWidth;

export const getPortraitOrientation = () => {
  if (mobileDetector.ios) {
    return (document.getElementById('app').getBoundingClientRect().height
      > document.getElementById('app').getBoundingClientRect().width) && isHandheld;
  }
  return !getOrientation() && isHandheld;
};

export function setDevice() {
  const { ClientType, } = qs.parse(location.search);

  switch (ClientType) {
  case 'mobile': {
    bowser.mobile = true;
    bowser.isHandheld = true;
    break;
  }
  case 'desktop': {
    bowser.mobile = false;
    bowser.tablet = false;
    bowser.isHandheld = false;
    break;
  }
  default:
    break;
  }
}
