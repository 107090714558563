export const PERFECT_PAIR = 'perfectPair';
export const TWENTY_ONE = 'twentyOne';
export const MAIN_BET = 'main';
export const INSURANCE_BET = 'insurance';
export const ANTE_BET = 'ante';

export const SERVER_MAIN_BET = 'RegularBet';
export const SERVER_TWENTY_ONE = '21+3';
export const SERVER_PERFECT_PAIR = 'PerfectPair';
export const SERVER_DOUBLE_BET = 'DoubleBet';
export const SERVER_SPLIT_BET = 'SplitBet';
export const SERVER_INSURANCE_BET = 'InsuranceBet';
export const SERVER_ANTE_BET = 'ante';

export const BASIC_BLACKJACK = 'basicBlackJack';
export const BlackJack = 'blackJack';
export const PLAYER_WINS = 'playerWins';
export const INSURANCE = 'insurance';
export const BET_BEHIND = 'betBehind';
export const BET_BEHIND_LABEL = 'betBehindLabel';
export const PERFECT_PAIR_SIDE_BET = 'perfectPairSideBet';
export const COLORED_PAIR = 'colorPair';
export const MIXED_PAIR = 'mixedPair';
export const SIDE_BETS_21_PLUS_3 = 'sideBets21Plus3';
export const SUITED_TRIPS = 'suitedTrips';
export const STRAIGHT_FLUSH = 'straightFlush';
export const THREE_OF_A_KIND = 'threeOfAKind';
export const STRAIGHT = 'straight';
export const FLUSH = 'flush';
