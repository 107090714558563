/* eslint-disable no-shadow */
import { flatMap, pluck, } from 'rxjs/operators';
import { ofType, } from 'redux-observable';
import { of, } from 'rxjs';
import { actions, } from '@ezugi/bootstrap';

import { NO_MORE_BETS, } from '@ezugi/constants';
import insuranceActions from '../../actions/insurance';
import { INSURANCE, } from '../../../components/Insurance/constants';
import {
  userSelector,
  authSelector,
  gameSelector,
  roundSelector,
  playerSeatsWithInsuranceCallSelector,
} from '../../selectors';
import { MAIN_BET, } from '../../../constants/betTypes';

const {
  betActions: { bet, },
  roundActions: { round, },
} = actions;
const { insurance, } = insuranceActions;

const buildInsurancePayload = (state, { seatId, insuranceCall, }) => {
  const { operatorId, table_id: tableId, } = authSelector(state);
  const { uid, nickname, currentPlayerToken, } = userSelector(state);
  const gameType = gameSelector(state).serverName;
  const { roundId, } = roundSelector(state);

  return {
    ClientId: `${operatorId}|${uid}|${tableId}`,
    MessageType: 'InsuranceCall',
    Nickname: nickname,
    destination: 'table',
    gameType,
    TableId: tableId,
    SeatId: seatId,
    InsuranceCallsMessageType: insuranceCall,
    roundId,
    CurrentPlayerToken: currentPlayerToken,
  };
};

const insuranceEpic = (action$, state$) => action$.pipe(
  ofType(insurance.request),
  pluck('payload'),
  flatMap(({ seatId, amount, type, }) => {
    const state = state$.value;

    const seatsWithInsuranceCall = playerSeatsWithInsuranceCallSelector(state);
    const roundPayload = {
      insurance: {
        ...state.round.insurance,
        prompt: seatsWithInsuranceCall.length > 1,
        ...(seatsWithInsuranceCall.length <= 1 && { roundStatus: NO_MORE_BETS, }),
      },
    };

    const actions = [
      round.set(roundPayload),
      insurance.send(buildInsurancePayload(state, { insuranceCall: type, seatId, })),
    ];

    /**
       * Update UI with insurance bet when calling INSURANCE
       */
    if (type === INSURANCE) {
      actions.push(
        bet.set({
          [seatId]: {
            [MAIN_BET]: {
              index: seatId,
              value: 0,
              insurance: amount,
              valid: true,
            },
          },
        })
      );
    }

    return of(...actions,);
  })
);

export default insuranceEpic;
