import { isNil, last, } from 'ramda';
import { GAME_RESULT, } from '@ezugi/constants';
import {
  authSelector,
  dealerSeatSelector,
  gameSelector,
  roundStatusSelector,
  tableIdSelector,
  tableSeatsWithScoreSelector,
  userSelector,
} from '../../../selectors';
// TODO: instead of seatActions, use notificationActions to trigger notification display for non-empty seats
import seatActions from '../../../actions/seat';
import { DEALER_SEAT_ID, SEAT_SCORE_SEPARATOR, } from '../constants';
import { SURRENDER, } from '../../../../components/Insurance/constants';
import { PLAYER_CALLED_DOUBLE, PLAYER_CALLED_SPLIT, PLAYER_CALLED_STAND, } from '../../../constants/decision';

const buildParams = (messageType, seatId, state) => {
  const user = userSelector(state);
  const authParams = authSelector(state);

  return {
    ClientId: `${user.operatorId}|${user.uid}|${authParams.table_id}`,
    MessageType: messageType,
    Nickname: user.nickname,
    SeatId: seatId,
    TableId: tableIdSelector(state).toString(),
    destination: 'table',
    gameType: gameSelector(state).serverName,
  };
};

export const createSeatRequestPayload = (messageType, seatId, state) => {
  const actions = [];
  const params = buildParams(messageType, seatId, state);
  actions.push(seatActions.seat.request(params));

  return params;
};

const hasAceCard = (cards) => last(cards) && String(last(cards).totalCardValues).indexOf(SEAT_SCORE_SEPARATOR) !== -1;

const normalizeCardsScore = (cards) => {
  if (hasAceCard(cards)) {
    const normalizedScore = last(cards).totalCardValues.split(SEAT_SCORE_SEPARATOR)[1];
    cards[cards.length - 1].totalCardValues = normalizedScore;
    return cards;
  }
  return null;
};

export const createSeatsScoresNormalizedPayload = (playerSeat, state) => {
  const roundStatus = roundStatusSelector(state);

  if (roundStatus === GAME_RESULT && playerSeat.seatId === DEALER_SEAT_ID) {
    // normalize only DEALER's cards
    const dealerSeat = dealerSeatSelector(state);
    const newCards = normalizeCardsScore(dealerSeat.cards);
    return (newCards) ? [ { seatId: dealerSeat.seatId, cards: newCards, }, ] : [];
  }

  // process and normalize only player cards with ACEs
  const seatsWithScore = tableSeatsWithScoreSelector(state);
  if (!isNil(seatsWithScore)) {
    const seatToBeNormalized = seatsWithScore.find(
      (seat) => (
        seat.seatId === playerSeat.seatId
        && hasAceCard(seat.cards)
        && (
          [ SURRENDER, ].includes(seat.insuranceDecision)
          || [ PLAYER_CALLED_DOUBLE, PLAYER_CALLED_STAND, PLAYER_CALLED_SPLIT, ].includes(seat.callBetDecision)
        )
      )
    );

    return (!isNil(seatToBeNormalized))
      ? [ { seatId: seatToBeNormalized.seatId, cards: normalizeCardsScore(seatToBeNormalized.cards), }, ] : [];
  }
  return [];
};
