import { VALID, } from './status';
import { userSelector, seatsSelector, } from '../selectors';

export const validateSeatOwner = (bet, state) => {
  const user = userSelector(state);
  const seats = seatsSelector(state);

  const betSeat = seats.filter((seat) => seat.seatId === bet.index);
  const ok = betSeat.length ? betSeat[0].playerId === user.clientId : false;

  return {
    ok,
    valid: ok,
    status: VALID,
    bet,
    actions: [],
  };
};
