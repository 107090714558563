/**
 * File used to calculate width and height of the app container based on the 16:9 ratio
 * TODO:: add comments
 */
import { createContext, } from 'react';
import {
  ifElse, pipe, prop, evolve, subtract, when, __, assoc,
} from 'ramda';
import bowser from 'bowser';

import { getPortraitOrientation, } from '../device';

const MOBILE_HEADER_HEIGHT = 19;
const isHigherRatio = ({ width, height, RATIO, }) => height / width > RATIO;
const getSmallerRatio = ({ width: windowWidth, height, RATIO, }) => {
  const REVERSED_RATIO = 1 / RATIO;
  const width = height * REVERSED_RATIO;
  const horizontalSpace = (windowWidth - width) / 2;
  return {
    height,
    width,
    horizontalSpace,
  };
};
const getHigherRatioSizes = ({ width, height: windowHeight, RATIO, }) => {
  const height = width * RATIO;
  const verticalSpace = (windowHeight - height) / 2;

  return {
    width,
    height,
    verticalSpace,
  };
};
/**
 * width, height, RATIO, isHandheld,
 */
const getLandscapeSizes = pipe(
  when(prop('isHandheld'), evolve({
    height: subtract(__, MOBILE_HEADER_HEIGHT),
  })),
  ifElse(isHigherRatio, getHigherRatioSizes, getSmallerRatio),
);
const getPortraitSizes = ({ width, RATIO, }) => ({
  width,
  isPortrait: true,
  height: width * RATIO,
});
const RATIO = 9 / 16;
const isHandheld = bowser.tablet || bowser.mobile;
/**
 * 3 cases:
 * landscape < 9/16 (desktop and mobile)
 * width, height, lateralSpace, verticalSpace
 * ===========================
 * landscape > 9/16 (tablet)
 * width, height, verticalSpace
 * ===========================
 * portrait
 * width, height
 * ===========================
 * @returns {{width, height, horizontalSpace: *, verticalSpace: *, isPortrait: *}}
 */
export const getScreenSizes = () => pipe(
  ifElse(getPortraitOrientation, getPortraitSizes, getLandscapeSizes),
  assoc('isHandheld', isHandheld),
)({ width: window.innerWidth, height: window.innerHeight, RATIO, isHandheld, });

export const SizesContext = createContext(getScreenSizes());
