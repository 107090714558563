import { css, } from 'react-emotion';
import { theme, } from '@ezugi/bootstrap';
import tinyColor from 'tinycolor2';
import bowser from 'bowser';

const { palette, } = theme;
const isHandheld = bowser.mobile || bowser.tablet;

const strokeColor = tinyColor(palette.highlight).brighten(90).toString();

export const resultMessageClassName = css`
  > div:nth-child(2) {
    font-size: ${isHandheld ? 20 : 40}px;
    > div {
      color: ${palette.highlight};
      font-size: ${isHandheld ? 40 : 64}px;
      font-weight: 900;
      text-align: center;
      text-shadow: 0 6px 13px #17192D;;
      -webkit-text-stroke: 0.8px ${strokeColor};
    }
  }

  > div:nth-child(3) {
    max-height: 25%;
  }
`;
