import { createActions, } from 'redux-actions';

export default createActions({
  GAME: {
    // REQUEST: null,
    // SUCCESS: null,
    // FAILURE: null,
    // SET: null,
    CLEAN: null,
    // START: null,
  },
  // SELECT_CHIP: {
  //   REQUEST: null,
  //   SUCCESS: null,
  //   FAILURE: null,
  //   SET: null,
  // },
  SETTINGS: {
    APPLY: null,
    SUCCESS: null,
    FAILURE: null,
  },
  GREETING: {
    REQUEST: null,
    SET: null,
  },
});
