import { handleActions, } from 'redux-actions';

import gameActions from '../actions/game';

import ls from '../../localstorage';
import { GAME, } from '../../constants';

const { game, settings, greeting, } = gameActions;

const defaultSettings = {
  hideSidebets: false,
  hideOthersChat: false,
  gameSounds: true,
};

export const getInitialSettings = () => {
  const [ err, savedSettings, ] = ls.get(GAME.SETTINGS);

  return err ? defaultSettings : savedSettings;
};

const initialState = {
  settings: getInitialSettings(),
  // selectChip: {},
  greeting: false,
  // ids of the user seats, used for calculating if rebet is possible
  userSeats: [],
};

export default handleActions(
  {
    // [game.set]: (state, { payload, }) => ({
    //   ...state,
    //   ...payload,
    // }),
    [game.clean]: (state) => {
      const currentSeats = [ ...state.seats, ];
      return {
        ...state,
        callBets: {
          decisions: [],
        },
        playerTurn: {},
        seats: currentSeats.map((seat) => ({ ...seat, ...{ callBetDecision: null, insuranceDecision: null, }, })),
      };
    },
    [greeting.set]: (state, { payload, }) => ({
      ...state,
      greeting: payload,
    }),
    // [selectChip.set]: (state, { payload, }) => ({
    //   ...state,
    //   selectedChip: payload,
    // }),
    [settings.apply]: (state, { payload, }) => ({
      ...state,
      settings: { ...state.settings, ...payload, },
    }),
  },
  initialState,
);
