/* eslint-disable no-shadow */
import { flatMap, pluck, } from 'rxjs/operators';
import { ofType, } from 'redux-observable';
import { of, } from 'rxjs';

import splitHandActions from '../../actions/splitHand';
import {
  authSelector, gameSelector, playersSelector, roundSelector, userSelector,
} from '../../selectors';
import { BETTING_CALL, } from '../seats/constants';

const { splitHand, } = splitHandActions;

const buildSplitHandPayload = (state, splitHandDecision) => {
  const { operatorId, table_id: tableId, } = authSelector(state);
  const { uid, nickname, currentPlayerToken, } = userSelector(state);
  const gameType = gameSelector(state).serverName;
  const { roundId, } = roundSelector(state);

  const { callBets: { seatId, }, } = playersSelector(state);

  return {
    ClientId: `${operatorId}|${uid}|${tableId}`,
    MessageType: BETTING_CALL,
    Nickname: nickname,
    destination: 'table',
    gameType,
    TableId: tableId,
    SeatId: seatId,
    BettingCallsMessageType: splitHandDecision,
    roundId,
    CurrentPlayerToken: currentPlayerToken,
  };
};

const splitHandEpic = (action$, state$) => action$.pipe(
  ofType(splitHand.request),
  pluck('payload', 'type'),
  flatMap((type) => {
    const payload = buildSplitHandPayload(state$.value, type);
    const actions = [ splitHand.send(payload), ];

    return of(...actions);
  })
);

export default splitHandEpic;
