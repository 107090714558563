import { handleActions, } from 'redux-actions';
import { PLACE_YOUR_BETS, CALL_BETS, } from '@ezugi/constants';
import { actions, } from '@ezugi/bootstrap';

const { roundActions: { round, }, } = actions;

const INITIAL_STATE = {
  insurance: {
    prompt: false,
    calledInsurance: false,
  },
  canSendNormalBetRequest: false,
  playerDecision: false,
  previousRoundStatus: null,
  showEarlyDecision: false,
  roundStatus: null,
  gameResults: {
    lastWin: 0,
    seats: [],
  },
};

export default handleActions(
  {
    [round.set]: (state, { payload, }) => ({
      ...INITIAL_STATE,
      ...state,
      ...payload,
      previousRoundStatus: state.roundStatus,
      showEarlyDecision: state.roundStatus === PLACE_YOUR_BETS
        ? false
        : state.roundStatus === CALL_BETS
          ? true
          : state.showEarlyDecision,
    }),
  },
  INITIAL_STATE
);
