import {
  prop, propOr, path, filter, keys, pipe, pathOr, head, values,
} from 'ramda';
import { createSelector, } from 'reselect';

import { MAIN_BET, } from '../../constants/betTypes';

export const tipsSelector = prop('tips');
const tableLimitsSelector = path([ 'table', 'limits', ]);
const gameSelector = pathOr({}, [ 'game', ]);
const currentLimitsSelector = createSelector(
  tableLimitsSelector,
  gameSelector,
  (limits, game) => limits[game.limitId] || head(values(limits))
);
const minBetSelector = createSelector(currentLimitsSelector, propOr(0, 'Min_Bet'));
const userBalanceSelector = pathOr(0, [ 'user', 'balance', ]);

export const betSelector = prop('bets');
export const currentBetsSelector = path([ 'bets', 'current', ]);
export const betsHistorySelector = path([ 'bets', 'history', ]);
export const lastBetsSelector = path([ 'bets', 'last', ]);
export const rebetStatusSelector = path([ 'bets', 'rebet', ]);
export const totalBetSelector = createSelector(
  betSelector,
  propOr(0, 'totalBet'),
);
export const hasBalanceToPlaceBetSelector = createSelector(
  userBalanceSelector,
  totalBetSelector,
  minBetSelector,
  (userBalance, totalBet, minBetLimit) => userBalance - totalBet >= minBetLimit,
);

export const validMainBetsKeysSelector = createSelector(
  currentBetsSelector,
  pipe(
    filter(path([ MAIN_BET, 'valid', ])),
    keys,
  ),
);
