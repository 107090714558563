import {
  contains, not, path, pathOr, propOr,
} from 'ramda';
import { createSelector, } from 'reselect';
import { LAST_BETS, PLACE_YOUR_BETS, } from '@ezugi/constants/';

export const roundSelector = path([ 'round', ]);
export const roundIdSelector = path([ 'round', 'roundId', ]);
export const roundStatusSelector = pathOr('', [ 'round', 'roundStatus', ]);
export const previousRoundStatusSelector = pathOr('', [ 'round', 'previousRoundStatus', ]);
export const availableDecisionsSelector = pathOr([], [ 'players', 'callBets', 'decisions', ]);
const canPlaceBets = (status) => contains(status, [ PLACE_YOUR_BETS, LAST_BETS, ]);
export const canPlaceBetsSelector = createSelector(roundStatusSelector, canPlaceBets);
export const idleGameBoardSelector = createSelector(canPlaceBetsSelector, not);
export const showEarlyDecisionSelector = path([ 'round', 'showEarlyDecision', ]);
export const gameResultsSelector = path([ 'round', 'gameResults', ]);
// TODO: this has to be fixed, either keep gameResults in round or in game
export const gameGameResultsSelector = path([ 'game', 'gameResults', ]);

export const wonSidebetsSelector = createSelector(
  gameGameResultsSelector,
  (gameResults) => propOr([], 'wonSidebets', gameResults)
);

const seatsResultSelector = createSelector(
  gameResultsSelector,
  (gameResults) => gameResults.seats
);

export const totalLastWin = createSelector(
  seatsResultSelector,
  (seats) => seats.reduce((acc, { WinAmount, }) => acc + WinAmount, 0)
);

export const isWinnerSelector = createSelector(
  totalLastWin,
  (amount) => amount > 0
);

export const lastWinSelector = createSelector(
  gameResultsSelector,
  (result) => result.lastWin
);
