import { createActions, } from 'redux-actions';

export default createActions({
  BET: {
    INIT: null,
    REQUEST: null,
    SUCCESS: null,
    FAILURE: null,
    ADD: null,
    SET: null,
    RESET: null,
    CLEAR: null,
    CACHE: null,
    REBET: null,
    APPLY: null,
    DOUBLE: null,
    REMOVE: null,
  },
  MAIN_BET: {
    ADD: null,
    SET: null,
    RESET: null,
  },
  TWENTY_ONE: {
    ADD: null,
    SET: null,
    RESET: null,
  },
  PERFECT_PAIR: {
    ADD: null,
    SET: null,
    RESET: null,
  },
  ANTE_BET: {
    ADD: null,
    SET: null,
    RESET: null,
  },
  TOTAL_BET: {
    SET: null,
  },
  HISTORY: {
    POP: null,
    RESET: null,
    APPLY: null,
  },
});
