import qs from 'qs';

export const getGameParams = () => ({
  ...qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }),
});

export const parseJson = (variable) => {
  let parsedStr;
  try {
    parsedStr = JSON.parse(variable);
    if (parsedStr && parsedStr.constructor === Object) {
      return parsedStr;
    }
  } catch (e) {
    return variable;
  }
  return variable;
};
