export default {
  BlackJack: '3:2',
  PlayerWins: '1:1',
  Insurance: '2:1',
  PerfectPair: '25:1',
  ColoredPair: '12:1',
  MixedPair: '6:1',
  SuitedTrips: '100:1',
  StraightFlush: '40:1',
  ThreeOfAKind: '25:1',
  Straight: '10:1',
  Flush: '5:1',
};
