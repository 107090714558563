import { propOr, } from 'ramda';

export const SURRENDER = 'Surrender';
export const INSURANCE = 'Insurance';
export const NO = 'Cancel';
export const EVEN_MONEY = 'EvenMoney';

export const INSURANCE_CALLS = 'InsuranceCalls';
export const INSURANCE_DECISION = 'InsuranceDecision';

export const INSURANCE_BUTTONS = {
  NO: 'noInsurance',
  SURRENDER: 'surrenderInsurance',
  YES: 'yesInsurance',
};

const DEFAULT_INSURANCE_BUTTONS_COLORS = {
  [INSURANCE_BUTTONS.NO]: '#9c276d',
  [INSURANCE_BUTTONS.SURRENDER]: '#09509d',
  [INSURANCE_BUTTONS.YES]: '#fd9e47',
};

export const getInsuranceButtonColor = (
  insuranceButton,
  actionButtonsCustomColors
) => propOr(DEFAULT_INSURANCE_BUTTONS_COLORS[insuranceButton], insuranceButton, actionButtonsCustomColors);
