export const HIT = 'Hit';
export const STAND = 'Stand';
export const SPLIT = 'Split';
export const DOUBLE = 'Double';

export const PLAYER_CALLED_DOUBLE = 'BettingCallsDouble';
export const PLAYER_CALLED_SPLIT = 'BettingCallsSplit';
export const PLAYER_CALLED_DO_NOT_SPLIT = 'BettingCallsDoNotSplit';
export const PLAYER_CALLED_STAND = 'BettingCallsStand';
export const PLAYER_CALLED_HIT = 'BettingCallsHit';

export const UBJ_DO_NOT_SPLIT = 'DoNotSplit';
