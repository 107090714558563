import React, { useEffect, useState, useRef, } from 'react';
import { cx, } from 'react-emotion';
import bowser from 'bowser';
import { useIntl, useAppState, } from '@ezugi/hooks';
import { selectors, } from '@ezugi/bootstrap';
import { PLACE_YOUR_BETS, } from '@ezugi/constants';


import { Button, } from '@ezugi/primitives';
import dealNowActions from '../../store/actions/dealNow';
import { playerSeatsIdsSelector, } from '../../store/selectors';
import CheckIcon from './check-icon.svg';
import * as styles from './style';


const isHandheld = bowser.mobile || bowser.tablet;
const spec = (state) => ({
  canPlaceBets: selectors.canPlaceBetsSelector(state),
  roundStatus: selectors.roundStatusSelector(state),
  mySeatIds: playerSeatsIdsSelector(state),
  enabled: state?.config?.blackjack?.dealNow,
});
const intlSpec = {
  keys: {
    dealNowLabel: 'games_ui.deal_now',
    waitingForVoteLabel: 'games_ui.waiting_for_other_players_to_vote',
  },
};

export default function DealNowButton() {
  const [ dealNow, setDealNow, ] = useState(false);
  const mySeatsCountRef = useRef();
  const [ { roundStatus, canPlaceBets, mySeatIds, enabled, }, dispatch, ] = useAppState(spec);
  const { dealNowLabel, waitingForVoteLabel, } = useIntl(intlSpec);

  useEffect(() => {
    roundStatus === PLACE_YOUR_BETS && setDealNow(false);
  }, [ roundStatus, ]);

  useEffect(() => {
    mySeatIds.length > mySeatsCountRef.current && setDealNow(false);
    mySeatsCountRef.current = mySeatIds.length;
  }, [ mySeatIds.length, ]);

  function onClick() {
    if (!dealNow && enabled) {
      setDealNow(true);
      dispatch(dealNowActions.dealNow.request());
    }
  }

  return (
    enabled && mySeatIds.length > 0
      ? (
        <Button
          flex={true}
          uppercase={true}
          label={!dealNow ? dealNowLabel : waitingForVoteLabel}
          data-e2e="bet-toolbar-deal-now"
          icon={dealNow ? CheckIcon : undefined}
          className={cx(styles.dealNowButton, {
            [styles.handheld]: isHandheld,
            [styles.ghost]: dealNow,
            [styles.withIcon]: dealNow,
            [styles.hidden]: !canPlaceBets,
          })}
          onClick={onClick}
        />
      ) : null
  );
}
