export const NOTIFICATION_MSG = {
  BET_BELOW_MIN_LIMITS_REMOVED: 'notifications.bet_below_min_limits_removed',
  BETTING_NOT_ALLOWED: 'notifications.bet_not_allowed',
  FAILED_GETTING_HISTORY: 'notifications.failed_getting_history',
  ROUND_CANCELLED_MESSAGE: 'messages.round_cancelled_message',
  VIDEO_UNAVAILABLE: 'notifications.video_unavailable',
  BET_OVER_TABLE_MAX_LIMIT_ADJUSTED: 'notifications.bet_over_table_max_limit_adjusted',
  BET_OVER_TABLE_MAX_LIMIT: 'notifications.bet_over_table_max_limit',
  BET_OVER_INDEX_MAX_LIMIT_ADJUSTED: 'notifications.bet_over_index_max_limit_adjusted',
  BET_BELOW_INDEX_MIN_LIMIT_ADJUSTED: 'notifications.bet_below_index_min_limit_adjusted',
  BET_BELOW_INDEX_MIN_LIMIT: 'notifications.bet_below_index_min_limit',
  LOW_BALANCE_MESSAGE: 'messages.low_balance_message',
  GROUP_BET_OVER_MAX_LIMITS: 'notifications.group_bet_over_max_limits',
  GROUP_BET_BELOW_MIN_LIMITS: 'notifications.group_bet_below_min_limits',
  TAKE_SEAT_AND_PLACE_BET: 'notifications.take_seat_and_place_bet',
  TAKE_SEAT: 'notifications.take_seat',
  WAIT_FOR_NEXT_GAME: 'notifications.wait_for_next_game',
  TAKE_ADDITIONAL_SEAT_AND_WAIT_FOR_NEXT_GAME: 'notifications.take_additional_seat_and_wait_for_next_game',
};
