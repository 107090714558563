import { contains, } from 'ramda';
import { PLACE_YOUR_BETS, LAST_BETS, } from '@ezugi/constants';
import { VALID, BETTING_NOT_ALLOWED, } from './status';

export const validateRoundStatus = (bet, { round: { roundStatus, }, }) => {
  const ok = contains(roundStatus, [ PLACE_YOUR_BETS, LAST_BETS, ]);
  return {
    ok,
    valid: ok,
    status: ok ? VALID : BETTING_NOT_ALLOWED,
    bet,
    actions: [],
  };
};
