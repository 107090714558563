export default {
  SEAT_TYPES: {
    NEW_SEAT_FREE: 'seat-free',
    EXTRA_SEAT_FREE: 'extra-seat-free',
    SEAT_TAKEN: 'seat-taken',
    EXTRA_SEAT_UNAVAILABLE: 'extra-seat-unavailable',
    SPLIT_SEAT: 'split',
  },

  NUMBER_OF_SEATS: 7,
};
