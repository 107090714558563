import { flatMap, pluck, map, } from 'rxjs/operators';
import { ofType, combineEpics, } from 'redux-observable';
import { of, concat, } from 'rxjs';
import { actions, } from '@ezugi/bootstrap';
import decisionActions from '../../actions/decision';

import {
  authSelector,
  gameSelector,
  userSelector,
  playersSelector,
  roundSelector,
} from '../../selectors';

const {
  roundActions: { round, },
} = actions;
const { decision, } = decisionActions;

const buildDecisionPayload = (state, decisionCall) => {
  const { operatorId, table_id: tableId, } = authSelector(state);
  const { uid, nickname, currentPlayerToken, } = userSelector(state);
  const { serverName, } = gameSelector(state);
  const {
    callBets: { seatId, },
  } = playersSelector(state);

  const { roundId, } = roundSelector(state);

  return {
    ClientId: `${operatorId}|${uid}|${tableId}`,
    MessageType: 'BettingCall',
    Nickname: nickname,
    destination: 'table',
    gameType: serverName,
    TableId: tableId,
    SeatId: seatId,
    BettingCallsMessageType: decisionCall,
    roundId,
    CurrentPlayerToken: currentPlayerToken,
  };
};

/* eslint-disable camelcase */
const decisionRequestEpic = (action$, state$) => action$.pipe(
  ofType(decision.request),
  pluck('payload'),
  map(({ type, }) => decision.send(buildDecisionPayload(state$.value, type)))
);

const resetEarlyEpic = (action$, state$) => action$.pipe(
  ofType(decision.send),
  pluck('payload', 'type'),
  flatMap(() => {
    const {
      callBets: { seatId, },
    } = playersSelector(state$.value);

    return concat(
      of(round.set({ playerDecision: false, })),
      of(decision.early({ seatId, earlyDecision: null, }))
    );
  })
);

export default combineEpics(decisionRequestEpic, resetEarlyEpic);
