import { createActions, } from 'redux-actions';

export default createActions({
  DECISION: {
    SEND: null,
    REQUEST: null,
    SUCCESS: null,
    EARLY: null,
  },
});
